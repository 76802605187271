import React, { useState } from "react"
import {
  getCompletedServices,
  getLatestWeekendService,
  getLiveServices,
  getUpcomingServices,
  isSecondaryService,
} from "../util/youtube"
import {
  hasServiceStarted,
  isFriday,
  shouldShowSermonOnlyService,
  wasPublishedInLastXDays,
} from "../util/date"
import { useLocation } from "react-router-dom"
import { useSettings } from "../../../context/SettingsContext"
/**
 * Context for managing live stream data from YouTube.
 *
 * We use this context to fetch live stream data from YouTube and parse it for consumption throughout the app.
 */

export const LivestreamContext = React.createContext({
  primaryLiveEventIsLoading: true,
  setPrimaryLiveEventIsLoading: () => {},
  secondaryLiveEventIsLoading: true,
  setSecondaryLiveEventIsLoading: () => {},
  primaryLiveEvent: [],
  setPrimaryLiveEvent: () => {},
  primaryStaticEvent: [],
  setPrimaryStaticEvent: () => {},
  secondaryLiveEvent: [],
  setSecondaryLiveEvent: () => {},
  primaryIsLiveEvent: false,
  setPrimaryIsLiveEvent: () => {},
  upcomingPrimaryEvent: false,
  setUpcomingPrimaryEvent: () => {},
  upcomingSecondaryEvent: false,
  setUpcomingSecondaryEvent: () => {},
  loadPrimaryLiveEvent: () => {},
  loadUpcomingPrimaryEvent: () => {},
  loadSecondaryLiveEvent: () => {},
  loadUpcomingSecondaryLiveEvent: () => {},
})

export const LivestreamProvider = ({ locale, children, env }) => {
  const location = useLocation()
  const { youTubeIds } = useSettings()
  const query = new URLSearchParams(location.search)
  const DEBUG = query.get("debug") == "true"
  const [primaryLiveEventIsLoading, setPrimaryLiveEventIsLoading] =
    React.useState(false)
  const [secondaryLiveEventIsLoading, setSecondaryLiveEventIsLoading] =
    React.useState(false)
  const [primaryLiveEvent, setPrimaryLiveEvent] = React.useState(null)
  const [primaryStaticEvent, setPrimaryStaticEvent] = React.useState(null)
  const [secondaryLiveEvent, setSecondaryLiveEvent] = useState(null)
  const [primaryIsLiveEvent, setPrimaryIsLiveEvent] = React.useState(false)
  const [upcomingPrimaryEvent, setUpcomingPrimaryEvent] = React.useState(false)
  const [upcomingSecondaryEvent, setUpcomingSecondaryEvent] =
    React.useState(false)

  const loadPrimaryLiveEvent = async () => {
    setPrimaryLiveEventIsLoading(true)

    // Load the Live feed to see if there are any existing live services
    const liveServices = await getLiveServices(
      youTubeIds.channel.gatewaychurchtv
    )

    if (liveServices?.items?.length > 0) {
      // Look through lives services to find any non-secondary services.
      const liveService =
        liveServices.items.find(
          (service) =>
            hasServiceStarted(service?.snippet?.actualStartTime) &&
            !isSecondaryService(service.snippet.title)
        ) || null

      if (liveService) {
        setPrimaryIsLiveEvent(true)
        setPrimaryLiveEventIsLoading(false)
        return setPrimaryLiveEvent(liveService)
      }
    }

    // If we're in Spanish mode, load the most recent Spanish message
    if (locale === "es") {
      const spanishService = await getLatestWeekendService(
        youTubeIds.playlist.espanol
      )
      if (spanishService) {
        setPrimaryLiveEventIsLoading(false)
        return setPrimaryLiveEvent(spanishService)
      }
    }

    // Otherwise During the week load the most recent English sermon-only video
    if (
      shouldShowSermonOnlyService({
        showSermonOnly: env?.showSermonOnly,
        sermonOnlyDay: env?.sermonOnlyDay || "Monday",
        sermonOnlyTime: env?.sermonOnlyTime || "10:30",
      })
    ) {
      const latestSermon = await getLatestWeekendService(
        youTubeIds.playlist.weekendService
      )
      DEBUG && console.log({ latestSermon })
      if (latestSermon && wasPublishedInLastXDays(latestSermon, 5)) {
        setPrimaryLiveEventIsLoading(false)
        return setPrimaryLiveEvent(latestSermon)
      }
    }

    // Otherwise load the latest completed live stream
    const weekendServices = await getCompletedServices(
      youTubeIds.playlist?.weekend_livestreams
    )
    if (weekendServices?.items?.length > 0) {
      setPrimaryLiveEventIsLoading(false)
      return setPrimaryLiveEvent(
        weekendServices.items.find(
          (service) => !isSecondaryService(service.snippet.title)
        ) || null
      )
    }
  }

  const loadUpcomingPrimaryEvent = async () => {
    // Check for upcoming live streams
    const upcomingServices = await getUpcomingServices(
      youTubeIds.channel.gatewaychurchtv
    )
    if (upcomingServices?.items?.length > 0) {
      setUpcomingPrimaryEvent(
        upcomingServices.items?.find(
          (service) => !isSecondaryService(service.snippet.title)
        ) || null
      )
    }
  }

  const loadSecondaryLiveEvent = async () => {
    setSecondaryLiveEventIsLoading(true)

    if (isFriday()) {
      // Check for live Shabbat service on Fridays
      const jewishService = await getLiveServices(youTubeIds.channel.jewish)
      if (
        jewishService?.items?.length > 0 &&
        hasServiceStarted(jewishService?.items?.[0]?.snippet?.actualStartTime)
      ) {
        setSecondaryLiveEventIsLoading(false)
        return setSecondaryLiveEvent(jewishService?.items?.[0])
      }
    }

    // Load the Live feed to see if there are any existing non-weekend live
    // services (this is only non-weekend because we only want to show the
    // weekend service on the main player and we're only checking this if
    // it's not a weekend)
    const liveServices = await getLiveServices(
      youTubeIds.channel.gatewaychurchtv
    )
    if (
      liveServices?.items?.length > 0 &&
      liveServices.items.some((service) =>
        isSecondaryService(service.snippet.title)
      )
    ) {
      setSecondaryLiveEventIsLoading(false)
      const liveService = liveServices.items.find((service) =>
        isSecondaryService(service.snippet.title)
      )
      if (liveService && hasServiceStarted(liveService.snippet.actualStartTime))
        return setSecondaryLiveEvent(liveService)
    }

    setSecondaryLiveEventIsLoading(false)
  }

  const loadUpcomingSecondaryLiveEvent = async () => {
    // Check for upcoming live streams
    const upcomingShabbatServices = await getUpcomingServices(
      youTubeIds.channel.jewish
    )
    DEBUG && console.log({ upcomingShabbatServices })

    if (upcomingShabbatServices?.items?.length > 0) {
      return setUpcomingSecondaryEvent(
        upcomingShabbatServices?.items?.[0] || null
      )
    }

    const upcomingOtherServices = await getUpcomingServices(
      youTubeIds.channel.gatewaychurchtv
    )
    DEBUG && console.log({ upcomingOtherServices })

    if (
      upcomingOtherServices?.items?.length > 0 &&
      upcomingOtherServices.items.some((service) =>
        isSecondaryService(service.snippet.title)
      )
    ) {
      return setUpcomingSecondaryEvent(
        upcomingOtherServices.items.find((service) =>
          isSecondaryService(service.snippet.title)
        ) || null
      )
    }
  }

  const state = React.useMemo(
    () => ({
      primaryLiveEvent,
      setPrimaryLiveEvent,
      secondaryLiveEvent,
      setSecondaryLiveEvent,
      primaryLiveEventIsLoading,
      setPrimaryLiveEventIsLoading,
      secondaryLiveEventIsLoading,
      setSecondaryLiveEventIsLoading,
      DEBUG,
      primaryIsLiveEvent,
      setPrimaryIsLiveEvent,
      upcomingPrimaryEvent,
      setUpcomingPrimaryEvent,
      loadPrimaryLiveEvent,
      loadUpcomingPrimaryEvent,
      upcomingSecondaryEvent,
      setUpcomingSecondaryEvent,
      loadSecondaryLiveEvent,
      loadUpcomingSecondaryLiveEvent,
      primaryStaticEvent,
      setPrimaryStaticEvent,
    }),
    [
      primaryLiveEventIsLoading,
      setPrimaryLiveEventIsLoading,
      secondaryLiveEventIsLoading,
      setSecondaryLiveEventIsLoading,
      primaryLiveEvent,
      setPrimaryLiveEvent,
      secondaryLiveEvent,
      setSecondaryLiveEvent,
      primaryIsLiveEvent,
      setPrimaryIsLiveEvent,
      upcomingPrimaryEvent,
      setUpcomingPrimaryEvent,
      loadPrimaryLiveEvent,
      upcomingSecondaryEvent,
      setUpcomingSecondaryEvent,
      primaryStaticEvent,
      setPrimaryStaticEvent,
    ]
  )

  return (
    <LivestreamContext.Provider value={state}>
      {DEBUG && (
        <div
          className="mb-4 d-flex align-items-center flex-wrap"
          style={{
            gap: "1rem",
          }}
        >
          <button
            className="btn btn-primary"
            onClick={async () => {
              setPrimaryLiveEventIsLoading(true)
              setTimeout(() => {
                setPrimaryLiveEventIsLoading(false)
              }, 5000)
            }}
          >
            Show Loading State
          </button>
          <button
            className="btn btn-primary"
            onClick={async () => {
              const liveServices = await getCompletedServices(
                youTubeIds.playlist?.weekend_livestreams
              )
              if (liveServices?.items?.length > 0) {
                setPrimaryIsLiveEvent(true)
                return setPrimaryLiveEvent(liveServices?.items?.[0])
              }
            }}
          >
            Trigger Live <strong>Weekend</strong> Service
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              setPrimaryIsLiveEvent(false)
            }}
          >
            Disable Live <strong>Weekend</strong> Service
          </button>
          <button
            className="btn btn-primary"
            onClick={async () => {
              const upcoming = await getCompletedServices(
                youTubeIds.playlist?.weekend_livestreams
              )
              if (upcoming?.items?.length > 0) {
                let upcomingService = structuredClone(upcoming?.items?.[0])
                // Adjust the start time to 10 seconds from now
                const startTime = new Date()
                startTime.setSeconds(startTime.getSeconds() + 10)
                upcomingService.snippet.scheduledStartTime =
                  startTime.toISOString()
                return setUpcomingPrimaryEvent(upcomingService)
              }
            }}
          >
            Load Upcoming Live <strong>Weekend</strong> Service
          </button>
        </div>
      )}
      {children}
      {DEBUG && (
        <div
          className="mb-4 d-flex align-items-center flex-wrap"
          style={{
            gap: "1rem",
          }}
        >
          <button
            className="btn btn-primary"
            onClick={async () => {
              const liveServices = await getCompletedServices(
                youTubeIds.playlist.jewish
              )
              if (liveServices?.items?.length > 0) {
                return setSecondaryLiveEvent(liveServices?.items?.[0])
              }
              alert("No live services found")
            }}
          >
            Trigger Live <strong>Shabbat</strong> Service
          </button>
          <button
            className="btn btn-primary"
            onClick={async () => {
              const upcoming = await getCompletedServices(
                youTubeIds.playlist.jewish
              )
              if (upcoming?.items?.length > 0) {
                let upcomingService = structuredClone(upcoming?.items?.[0])
                // Adjust the start time to 10 seconds from now
                const startTime = new Date()
                startTime.setSeconds(startTime.getSeconds() + 10)
                upcomingService.snippet.scheduledStartTime =
                  startTime.toISOString()
                return setUpcomingSecondaryEvent(upcomingService)
              }
            }}
          >
            Load Upcoming <strong>Shabbat</strong> Service
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              return setSecondaryLiveEvent(null)
            }}
          >
            Disable Live Shabbat Service
          </button>
        </div>
      )}
    </LivestreamContext.Provider>
  )
}

export const useLivestream = () => {
  const context = React.useContext(LivestreamContext)
  return { ...context }
}
